// scroll bar
import 'simplebar/src/simplebar.css';
// editor
import 'react-quill/dist/quill.snow.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import Login from './pages/Login';
import { useEffect } from 'react';
// export default function AuthWrapper() {
//   return !JSON.parse(localStorage.getItem('authentication')) ? <Login /> : <App />;
// }
export default function AuthWrapper() {
  const isAuthenticated = JSON.parse(localStorage.getItem('authentication'));
  let deferredPrompt;

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceWorker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

    // Check if the app is not installed and the user is authenticated
    if (!window.matchMedia('(display-mode: standalone)').matches && isAuthenticated) {
      // Listen for the beforeinstallprompt event
      const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        deferredPrompt = event;
        showInstallNotification();
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }
  }, [isAuthenticated]);

  function showInstallNotification() {
    // You can customize the appearance and content of the notification
    const notification = new Notification('Install PMS', {
      body: 'Get the full experience by installing our app.',
    });

    // Optionally, add an event listener for user interaction with the notification
    notification.addEventListener('click', () => {
      // Trigger the installation prompt when the user clicks the notification
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
      }
    });
  }

  return isAuthenticated ? <App /> : <Login />;
}


ReactDOM.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <AuthWrapper />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  </HelmetProvider>
  , document.getElementById('root')
);