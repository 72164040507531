import React from 'react';
import { Stack, Skeleton, Box, Paper } from '@mui/material';

export default function SkeletonWbsListInTable() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
            {[1, 2, 3].map((item) => (
                <React.Fragment key={item}>
                <Skeleton variant="text" height={32} />
                </React.Fragment>
            ))}
        </Stack>
      </Paper>
    </Box>
  );
}
