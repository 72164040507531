import React, { useState } from 'react'
// @mui
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { SkeletonWbsListInTable } from '../../components/skeleton'
import { useTheme } from '@mui/material/styles';
// import css 
import '../../sections/@dashboard/timesheet/TimeSheetNewForm.css';
import { DataGrid } from '@mui/x-data-grid';

import Label from 'src/components/Label';
import { statusColor } from 'src/utils/statusColors';
;
const ICON = {
    mr: 1,
    width: 20,
    height: 20,
};

export const scrollbarStyles = `
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      background: #00ab569d;
      borderRadius: 5px;
    }
  `;

const DeliveryReportDetails = ({ isMonthly, data, isLoading }) => {
    const theme = useTheme();
    const [pageSize, setPageSize] = useState(20);
    const [cols, setCols] = useState([
        {
            field: "w_table_year",
            headerName: "Year",
            width: 80,
        },
        {
            field: "wbsMonth",
            headerName: "Month",
            width: 95,
        },
        {
            field: "cust_company",
            headerName: "Customer",
            width: 160,
        },
        {
            field: "pt_name",
            headerName: "Project",
            width: 130,

        },
        {
            field: "w_table_wbsname",
            headerName: "WBS",
        },
        {
            field: "users",
            headerName: "TEAM",
        },
        {
            field: "res_plan_hrs",
            headerName: "Planned Hours",
            width: 100,
            renderCell: (cell) => `${cell.formattedValue} hrs`

        },
        {
            field: "res_bill_hrs",
            headerName: "Billable Hours",
            width: 100,
            renderCell: (cell) => `${cell.formattedValue} hrs`

        },
        {
            field: "unbill_hrs",
            headerName: "Non-Billable Hours",
            width: 100,
            renderCell: (cell) => `${cell.formattedValue} hrs`
        },
        {
            field: "w_table_inv_hrs",
            headerName: "Invoice Hours",
            width: 100,
            renderCell: (cell) => `${cell.formattedValue} hrs`

        },
        {
            field: "dev_descr",
            headerName: "Development Status",
            width: 130,
            renderCell: (cell) => (<Label
                variant={
                    theme.palette.mode === 'light' ? 'ghost' : 'filled'
                }
                color={statusColor[cell.row?.dev_key]}
            >
                {cell.formattedValue}
            </Label>)

        },
        {
            field: "inv_descr",
            headerName: "Invoice Status",
            width: 130,
            renderCell: (cell) => (<Label
                variant={
                    theme.palette.mode === 'light' ? 'ghost' : 'filled'
                }
                color={statusColor[cell.row?.inv_key]}
            >
                {cell.formattedValue}
            </Label>)
        },
    ]);
    let summary = {
        bill: 0, unbill: 0, inv: 0, planned: 0
    }
    if (data.length > 0) {
        data.forEach(obj => {
            summary.planned += Number(obj.w_table_plan_hrs);
            summary.bill += Number(obj.w_table_bill_hrs);
            summary.unbill += Number(obj.unbill_hrs);
            summary.inv += Number(obj.w_table_inv_hrs);
        });
    }
    const initialStateOfRowsPerOpt = [10, 20, 30, 40, 50, { value: -1, label: 'All' }];
    const [rowsperoptions, setRowsperoptions] = useState(initialStateOfRowsPerOpt);
    const handlePageSizeChange = (newPageSize) => {
        setRowsperoptions(newPageSize === -1 ? [...initialStateOfRowsPerOpt, data.length] : initialStateOfRowsPerOpt);
        setPageSize(newPageSize === -1 ? data.length : newPageSize);
    };

    return (
        <Grid item xs={12} md={12}>
            <Card component={Stack} direction={'row'} rowGap={2} columnGap={2} flexWrap={'wrap'} sx={{ p: 1 }}>
                <Typography variant="p" sx={{ fontWeight: '700' }}>Summary: </Typography>
                <Typography variant="p" sx={{ fontWeight: '700' }}><Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={"success"}>{`Planned Hours`}: {summary?.planned} hrs</Label></Typography>
                <Typography variant="p" sx={{ fontWeight: '700' }}><Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={"info"}>{`Bill Hours`}: {summary?.bill} hrs</Label></Typography>
                <Typography variant="p" sx={{ fontWeight: '700' }}><Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={"secondary"}>{`Non-Bill Hours`}: {summary?.unbill} hrs</Label></Typography>
                <Typography variant="p" sx={{ fontWeight: '700' }}><Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={"success"}>{`Invoice Hours`}: {summary?.inv} hrs</Label></Typography>
            </Card>
            <Card sx={{ p: 1, mt: 1 }} key={isMonthly.toString()}>
                {isLoading && <SkeletonWbsListInTable /> ||
                    <ThemeProvider theme={theme}>
                        <GlobalStyles styles={`.MuiDataGrid-root ${scrollbarStyles}`} />
                        <DataGrid
                            rows={data}
                            columns={cols}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            rowHeight={35}
                            pageSize={pageSize}
                            rowsPerPageOptions={rowsperoptions}
                            onPageSizeChange={handlePageSizeChange}
                            autoHeight

                        />
                    </ThemeProvider>

                }
            </Card>

        </Grid>
    )
}

export default DeliveryReportDetails