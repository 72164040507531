// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/identify'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};


export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, 'dashboard_em'),
    app_pm: path(ROOTS_DASHBOARD, 'dashboard_pm'),
    app_mm: path(ROOTS_DASHBOARD, 'dashboard_mm'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, 'reports'),
  },
  dreport: {
    root: path(ROOTS_DASHBOARD, 'ts_utilization_report'),
  },
  hoursReport: {
    root: path(ROOTS_DASHBOARD, 'hours'),
  },
  job: {
    root: path(ROOTS_DASHBOARD, 'jobs'),
    newJob: path(ROOTS_DASHBOARD, 'jobs/new'),
    editJob: path(ROOTS_DASHBOARD, 'jobs/edit/:id'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, 'profile'),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, 'customer'),
    newCustomer: path(ROOTS_DASHBOARD, '/customer/new'),
    updateCustomer: path(ROOTS_DASHBOARD, '/customer/update/:id'),

  },
  project: {
    root: path(ROOTS_DASHBOARD, 'project'),
    newProject: path(ROOTS_DASHBOARD, '/project/new'),
    updateProject: path(ROOTS_DASHBOARD, '/project/update/:id'),
  },
  wbs: {
    root: path(ROOTS_DASHBOARD, 'wbs'),
    newWbs: path(ROOTS_DASHBOARD, '/wbs/new'),
  },
  resourceShedule: {
    root: path(ROOTS_DASHBOARD, 'resourceUtilization'),
  },
  deliveryReport: {
    root: path(ROOTS_DASHBOARD, 'deliveryReport'),
  },
  timesheet: {
    root: path(ROOTS_DASHBOARD, 'timesheet'),
    newTimesheet: path(ROOTS_DASHBOARD, 'timesheet'),
    approveTimesheet: path(ROOTS_DASHBOARD, 'approveTimesheet')
  },
  resReport: {
    root: path(ROOTS_DASHBOARD, 'resourceallocation'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, 'user'),
    profile: path(ROOTS_DASHBOARD, 'user/profile'),
    cards: path(ROOTS_DASHBOARD, 'user/cards'),
    newUser: path(ROOTS_DASHBOARD, 'user/new'),
    editById: path(ROOTS_DASHBOARD, `user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, 'user/account'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
