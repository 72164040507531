
// @mui
import { Box, Button, Container, Switch, Typography } from '@mui/material';
import { Card, Grid, Stack, TextField, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// routes
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect } from '../../components/hook-form';
import { useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// sections
import { useEffect, useState, useMemo } from 'react';

import Axios from 'src/utils/Axios';
import API from 'src/utils/Api';
import ResourceScheduleReports from './ResourceScheduleReports';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------

export default function ResourceSchedule() {
    const { themeStretch } = useSettings();
    const [isMonthly, setIsMonthly] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);;
    const [customerList, setCustomerList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [data, setData] = useState([]);
    const [filterString, setFilterString] = useState('');
    const [customerProjectList, setCustomerProjectList] = useState([])
    const [selectedWeek, setSelectedWeek] = useState({
        dates: null,
        startDate: null,
        endDate: null,
        weekNo: null
    });
    const [allCriteria, setAllCriteria] = useState({
        projectId: '',
        week: '',
        month: '',
        employee: '',
        status: '',
        customer: ''
    });
    const defaultValues = useMemo(
        () => ({
            customer: '',
            project: '',
            employee: '',
            status: '',
        }),
        []
    );
    const NewUserSchema = Yup.object().shape({});

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        getValues,
        handleSubmit,
        resetField,
    } = methods;
    useEffect(() => {
        watch("customer") && (setProjectDropdown(watch("customer")));
    }, [watch("customer"), location])

    const getAllProjects = async () => {
        try {
            const result = await Axios.GET(API.newProjectdata);
            setProjectList(result.projectData);
            console
            return result?.projectData || [];
        } catch (err) {
            console.error('Error in getAllProjects:', err);
            return [];
        }
    };
    const handleYearChange = (date) => {
        setSelectedYear(date);
    };

    const handleMonthChange = (date) => {
        setSelectedMonth(date);
    };

    const callGetMasterData = () => {
        Axios.GET(API.masterData + `?role=${JSON.parse(localStorage.getItem('user'))?.userRole?.role_desc?.toLowerCase()}`)
            .then((response) => {
                setCustomerDropdown(response.customerList);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const setCustomerDropdown = (data) => {
        const customerList = [...new Map(data.map(item => [item.id, item])).values()];
        setCustomerList(customerList);
    };
    const setProjectDropdown = async (customerId) => {
        let customerProjectList = [];
        if (projectList.length === 0) {
            const pData = await getAllProjects();

            customerProjectList = [...new Map(pData?.filter(item => item.cust_id == customerId).map(item => [item.id, item])).values()];
            setCustomerProjectList(customerProjectList);
            return
        };
        customerProjectList = [...new Map(projectList.filter(item => item.cust_id == customerId).map(item => [item.id, item])).values()];
        setCustomerProjectList(customerProjectList);
    };

    const onSubmit = async () => {
        const userFormData = getValues();
    };
    function getResourceUtilization() {
        setLoading(true)
        Axios.GET(API.getResourceUtilizationList + `?year=${selectedYear ? new Date(selectedYear).getFullYear() : ''}&month=${selectedMonth ? new Date(selectedMonth).getMonth() + 1 : ''}&customer=${watch('customer')}&project=${watch('project')}`)
            .then((response) => {
                if (response) {
                    setData(response.resourceUtilizationList)
                    setLoading(false)
                };
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            });
    };

    useEffect(() => {
        const nonEmptyCriteria = Object.entries(allCriteria).filter(([key, value]) => {
            return value;
        });

        const filterString = nonEmptyCriteria.map(([key, value]) => {
            return `${key}-${value}`;
        }).join('+');

        setFilterString(`${filterString}`);
    }, [allCriteria]);

    useEffect(() => {
        getResourceUtilization()
    }, [selectedYear, selectedMonth, watch('customer'), watch('project')])

    useEffect(() => {
        callGetMasterData();
        getAllProjects();
    }, [])

    return (
        <Page title="PMS: Resorce Utilisation">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <HeaderBreadcrumbs
                        heading="Resource Utilisation"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: `Resource Utilisation Report` }
                            // { name: `Maximum rows for download: 5` }
                        ]}
                    />
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                        <Box sx={{ display: 'flex', width: '100%', px: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={['year']}
                                    label="Year"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    openTo="year"
                                    format="yyyy"
                                    renderInput={(params) => {
                                        return <TextField
                                            sx={{ width: "100%", mr: 2 }}
                                            {...params} />
                                    }}
                                />
                                <DatePicker
                                    views={['month']}
                                    label="Month"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    openTo="month"
                                    format="MMMM yyyy"
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            sx={{ width: "100%", mr: 2 }}
                                            inputProps={{
                                                readOnly: true,
                                                value: params.inputProps.value ? new Date(new Date().getFullYear(), new Date(params.inputProps.value).getMonth(), 1).toLocaleString('en-US', { month: 'long' }) : ''
                                            }}
                                        />
                                    }}
                                />
                            </LocalizationProvider>

                        
                        <Box sx={{ display: 'flex', width: '100%', px: 1 }}>
                            <RHFSelect name="customer" label="Customer">
                                <option value="" />
                                {customerList.length > 0 && customerList.map((option, i) => (
                                    <option key={i} value={option.id}>
                                        {option.company}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', px: 1 }}>
                            <RHFSelect name="project" label="Project">
                                <option key=""></option>

                                {customerProjectList.length > 0 && customerProjectList.map((option, i) => (
                                    <option key={i} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Box>
                        {
                            (selectedMonth || selectedYear || watch('customer') || watch("project")) ? <IconButton sx={{ color: "#F95959" }} onClick={() => { setSelectedMonth(null); setSelectedYear(null); resetField("customer", { defaultValue: "" }); resetField("project", { defaultValue: "" }) }} aria-label="delete">
                                <HighlightOffIcon sx={{ color: "#F95959" }} />
                            </IconButton> : null
                        }
                        </Box>
                    </FormProvider>

                </Box>

                <ResourceScheduleReports isMonthly={isMonthly} data = {data} isLoading = {isLoading}  />
            </Container>
        </Page>
    );
}
