export const statusColor = {
    OH: 'warning',
    IS: 'warning',
    OT: 'info',
    IP: 'success',
    OR: 'info',
    OY: 'info',
    OP: 'warning',
    DR: 'error',
    DT: 'error',
    CL: 'error',
    CD: 'info',
    PI: 'info',
    NB: 'warning',
    IV: 'success',
    CC: 'warning',
    NA: 'error',
    CN: 'warning', //has concerns
    ES: 'error', //escaleted
};

// Dev Status
// ==========
// Open
// In progress
// Closed
// Opportunity
// Dormant
// On hold
// Continued
// Not Applicable

// Inv Status
// ==========
// Open
// In Progress
// Invoiced
// Concern
// Partial Invoiced
// Non Billable
// Not Applicable