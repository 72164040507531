const HOST = process.env.REACT_APP_API_URL;
const API = {
  // Login endpoints
  Login: HOST.concat('/functions/Login'),
  Logout: HOST.concat('/functions/Logout'),
  Start: HOST.concat('/functions/Start'),

  // Forget password endpoints

  Resetpassword: HOST.concat('/auth/identify'),

  // get all user
  userList: HOST.concat('/wbs/users'),
  resourcesList: HOST.concat('/wbs/resources'),

  // user endpoints
  userCreate: HOST.concat('/user/newUser'),
  getUserData: HOST.concat('/user/getUserData'),
  getUserById: HOST.concat('/user/getUserById'),
  updateUser: HOST.concat('/user/updateUser'),
  searchUser: HOST.concat('/user/searchUser'),
  getProfileDetails: HOST.concat('/user/getProfileDetails'),
  getRoleDataByUserId: HOST.concat('/user/getRoleDataByUserId'),

  // wbs endpoints
  wbsCreate: HOST.concat('/wbs/wbsCreate'),
  wbsData: HOST.concat('/wbs/wbsData'),
  wbsUpdate: HOST.concat('/wbs/wbsUpdate'),
  wbsDelete: HOST.concat('/wbs/wbsDelete'),
  idData: HOST.concat('/wbs/idData'),
  wbsSearch: HOST.concat('/wbs/wbsSearch'),
  projectList: HOST.concat('/wbs/projectList'),
  projectWbsList: HOST.concat('/wbs/projectwbsdata'),
  resourcePlannedHrs: HOST.concat('/wbs/resourcePlannedHrs'),
  wbsResources: HOST.concat('/wbs/twoTableData'),
  wbsResourcesWRTWbsIds: HOST.concat('/wbs/resourcesWRTWbs'),
  getLastMonthWbs: HOST.concat('/wbs/getLastMonthWbs'),
  // dropdownlist endpoints

  wbsDropDown: HOST.concat('/dropdownlist/wbsDropDown'),
  dropDown: HOST.concat('/dropdownlist/dropDown'),
  getListdata: HOST.concat('/dropdownlist/getListdata'),
  getCountry: HOST.concat('/dropdownlist/getCountry'),
  getDepartment: HOST.concat('/dropdownlist/getDepartment'),
  getSkills: HOST.concat('/dropdownlist/getSkills'),
  getRoles: HOST.concat('/dropdownlist/getRoles'),
  pwbs_statuslist: HOST.concat('/dashboard/pwbs_statuslist'),

  // project endpoints

  newProject: HOST.concat('/project/newProject'),
  masterData: HOST.concat('/project/masterData'),
  userRole: HOST.concat('/project/userRole'),
  User: HOST.concat('/project/User'),
  projectsearch: HOST.concat('/project/projectsearch'),
  deleteProject: HOST.concat('/project/deleteProject'),
  newProjectdata: HOST.concat('/project/newProjectdata'),
  projectUpdate: HOST.concat('/project/projectUpdate'),
  projIddata: HOST.concat('/project/projIddata'),
  updateAllProjects: HOST.concat('/project/updateAllProjects'),

  // customer endpoints

  createCustomer: HOST.concat('/customer/createCustomer'),
  updateCustomer: HOST.concat('/customer/updateCustomer'),
  getCustomerById: HOST.concat('/customer/getCustomerById'),
  getCustomer: HOST.concat('/customer/getCustomer'),
  getCustomerlist: HOST.concat('/customer/getCustomerlist'),
  delCustomer: HOST.concat('/customer/delCustomer'),
  search: HOST.concat('/customer/search'),
  getAccManager: HOST.concat('/customer/getAccManager'),

  // timesheet endpoints

  masterList: HOST.concat('/timesheet/masterList'),
  updateTimesheet: HOST.concat('/timesheet/updateTimesheet'),
  submitTask: HOST.concat('/timesheet/submitTask'),
  getUser: HOST.concat('/timesheet/getUser'),
  getUsers: HOST.concat('/timesheet/getUsers'),
  getPieChartData: HOST.concat('/timesheet/getPieChartData'),
  getUserActiveWbs: HOST.concat('/timesheet/getUserActiveWbs'),
  saveTimesheet: HOST.concat('/timesheet/saveTimesheet'),
  getTimesheet: HOST.concat('/timesheet/getTimesheet'),

  // approveTimesheet endpoints

  submittedTimesheet: HOST.concat('/approveTimesheet/submittedTimesheet'),
  approvalSheet: HOST.concat('/approveTimesheet/approvalSheet'),
  approveTimeSheet: HOST.concat('/approveTimesheet/approveTimeSheet'),
  rejectTimeSheet: HOST.concat('/approveTimesheet/rejectTimeSheet'),
  searchTimesheetByCriteria: HOST.concat('/approveTimesheet/searchTimesheetByCriteria'),
  reviewTimesheet: HOST.concat('/approveTimesheet/reviewTimesheet'),

  // reports
  searchTimesheetByCriteriaReport: HOST.concat('/reports/searchTimesheetByCriteria'),
  searchMonthlyTsReport: HOST.concat('/reports/searchMonthlyTsReport'),
  downloadExcel: HOST.concat('/reports/downloadExcel'),
  hours: HOST.concat('/reports/hours'),
  mhours: HOST.concat('/reports/mhours'),
  submittedTimesheetsReport: HOST.concat('/reports/submittedTimesheetsReport'),


  // task endpoints

  newTask: HOST.concat('/task/newTask'),
  newTaskGetData: HOST.concat('/task/newTaskGetData'),
  taskUpdate: HOST.concat('/task/taskUpdate'),
  deleteTask: HOST.concat('/task/deleteTask'),

  // User Dashboard endpoints

  getTimesheetCountByDate: HOST.concat('/userdashboard/getTimesheetCountByDate'),
  getUtilizedHourByDate: HOST.concat('/userdashboard/getUtilizedHourByDate'),
  getChartData: HOST.concat('/userdashboard/getChartData'),
  usersproject: HOST.concat('/userdashboard/usersproject'),
  getTotalDetails: HOST.concat('/userdashboard/getTotalDetails'),
  getBarChartData: HOST.concat('/userdashboard/getBarChartData'),
  pendingTs: HOST.concat('/userdashboard/pendingTs'),
  pendingTsApproval: HOST.concat('/userdashboard/pendingTsApproval'),
  pendingForAp: HOST.concat('/userdashboard/pendingForAp'),
  // Manager Dashboard endpoints

  assignedProject: HOST.concat('/managerDashboard/assignedProject'),
  team: HOST.concat('/managerDashboard/team'),
  pendingTsMM: HOST.concat('/managerDashboard/pendingTsMM'),
  isTsPendingForWbs: HOST.concat('/managerDashboard/isTsPendingForWbs'),
  projectDetails: HOST.concat('/managerDashboard/projectDetails'),
  getBarChartDataMM: HOST.concat('/managerDashboard/getBarChartDataMM'),
  getTotalDetailsMM: HOST.concat('/managerDashboard/getTotalDetailsMM'),
  timesheetDetails: HOST.concat('/managerDashboard/timesheetDetails'),
  resourceInfo: HOST.concat('/managerDashboard/resourceInfo'),
  getChartManagerData: HOST.concat('/managerDashboard/getBarChartData'),
  getResourceUtilizationList: HOST.concat('/resourceUtilization/getResourceUtilizationList'),
  getDeliveryReportList: HOST.concat('/deliveryReports/deliveryReport'),
  // Admin/CxO Dashboard endpoints

  adminProjectdata: HOST.concat('/adminDashboard/adminProjectdata'),
  pendingTsPM: HOST.concat('/adminDashboard/pendingTsPM'),
  adminProjectdetails: HOST.concat('/adminDashboard/adminProjectdetails'),
  selectedProjectdata: HOST.concat('/adminDashboard/selectedProjectdata'),
  getTotalDetailsPM: HOST.concat('/adminDashboard/getTotalDetailsPM'),
  getBarChartDataPM: HOST.concat('/adminDashboard/getBarChartDataPM'),
  setDate: HOST.concat('/adminDashboard/setDate'),
  overallData: HOST.concat('/adminDashboard/overallData'),
  resourceAllocation: HOST.concat('/adminDashboard/resourceAllocation'),
  utilization: HOST.concat('/adminDashboard/utilization'),
  people: HOST.concat('/adminDashboard/people'),
  featuresList: HOST.concat('/adminDashboard/featuresList'),
  selectedProjectutilization: HOST.concat('/adminDashboard/selectedProjectutilization'),

  // Password Reset endpoints
  passwordChange: HOST.concat('/password/passwordChange'),
  checkPassword: HOST.concat('/password/checkPassword'),
  checkEmail: HOST.concat('/password/checkEmail'),

  // User History endpoints

  timesheethistory: HOST.concat('/history/timesheethistory'),
  timesheetsearch: HOST.concat('/history/timesheetsearch'),

  // Manager History endpoints

  managerhistory: HOST.concat('/history/managerhistory'),
  managersearch: HOST.concat('/history/managersearch'),

  // Admin Admin endpoints

  adminhistory: HOST.concat('/history/adminhistory'),
  adminsearch: HOST.concat('/history/adminsearch'),

  // Background jobs endpoints
  getAllScripts: HOST.concat('/jobs/getAllScripts'),
  getAllJobs: HOST.concat('/jobs/getAllJobs'),
  getJobById: HOST.concat('/jobs/getJobById'),
  createJob: HOST.concat('/jobs/createJob'),
  updateJob: HOST.concat('/jobs/updateJob'),
  deleteJob: HOST.concat('/jobs/deleteJob'),
};
export default API;