// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import GradingIcon from '@mui/icons-material/Grading';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_analytics'),
  project: getIcon('ic_project'),
};

const role = JSON.parse(localStorage.getItem('user'))?.userRole?.role_desc?.toLowerCase();
let navConfig = [];
let item = [
  {
    title: "",
    path: "",
    Icon: ICONS.dashboard,
  }
]

switch (role) {
  case "admin":
    navConfig = [
      // GENERAL 
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app_pm, icon: ICONS.dashboard },
          { title: 'User', path: PATH_DASHBOARD.user.root, icon: ICONS.user },
          { title: 'Customer', path: PATH_DASHBOARD.customer.root, icon: <SvgIconStyle src={'/assets/icons/navbar/ic_user.svg'} sx={{ width: 1, height: 1 }} /> },
          { title: 'Project', path: PATH_DASHBOARD.project.root, icon: ICONS.analytics },
          { title: 'WBS', path: PATH_DASHBOARD.wbs.root, icon: <Diversity3Icon sx={{ width: 1, height: 1 }} /> },
          {
            title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar,
            children: [
              { title: 'Create', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
              { title: 'Approve', path: PATH_DASHBOARD.timesheet.approveTimesheet, icon: <GradingIcon sx={{ width: 1, height: 1 }} /> },
            ]
          },
          { title: 'Job Schedule', path: PATH_DASHBOARD.job.root, icon: <EventRepeatIcon sx={{ width: 1, height: 1 }} /> },
          {
            title: 'Reports', path: PATH_DASHBOARD.reports.root, icon: <HourglassBottomIcon sx={{ width: 1, height: 1 }} />,
            children: [
              { title: 'Timesheet', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Hours', path: PATH_DASHBOARD.hoursReport.root, icon: <HourglassBottomIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Resource Allocation', path: PATH_DASHBOARD.resReport.root, icon: <AnalyticsIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Delivery', path: PATH_DASHBOARD.deliveryReport.root, icon: <TrendingUpIcon sx={{ width: 1, height: 1 }} /> },]
          },
          {
            title: 'Utilization', icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} />,
            children: [
              { title: 'Timsheet', path: PATH_DASHBOARD.dreport.root, icon: <AnalyticsIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Resource ', path: PATH_DASHBOARD.resourceShedule.root, icon: <LeaderboardIcon sx={{ width: 1, height: 1 }} /> },
            ]
          },
        ],
      },
    ];
    break;
  case "cxo":
    navConfig = [
      // GENERAL
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
          { title: 'Customer', path: PATH_DASHBOARD.customer.root, icon: ICONS.user },
          { title: 'Project', path: PATH_DASHBOARD.project.root, icon: ICONS.analytics },
          { title: 'WBS', path: PATH_DASHBOARD.wbs.root, icon: ICONS.analytics },
          { title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
          { title: 'Approve Timesheet', path: PATH_DASHBOARD.timesheet.approveTimesheet, icon: ICONS.calendar },

        ],
      }
    ];
    break;
  case "account manager":
    navConfig = [
      // GENERAL
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app_mm, icon: ICONS.dashboard },
          { title: 'Project', path: PATH_DASHBOARD.project.root, icon: ICONS.analytics },
          { title: 'WBS', path: PATH_DASHBOARD.wbs.root, icon: <Diversity3Icon sx={{ width: 1, height: 1 }} /> },
          { title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
          { title: 'Approve Timesheet', path: PATH_DASHBOARD.timesheet.approveTimesheet, icon: <GradingIcon sx={{ width: 1, height: 1 }} /> },
          { title: 'Timesheet Report', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} /> },
        ],
      }
    ];
    break;
  case "manager":
    navConfig = [
      // GENERAL
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app_mm, icon: ICONS.dashboard },
          { title: 'Project', path: PATH_DASHBOARD.project.root, icon: ICONS.analytics },
          { title: 'WBS', path: PATH_DASHBOARD.wbs.root, icon: <Diversity3Icon sx={{ width: 1, height: 1 }} /> },
          { title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
          { title: 'Approve Timesheet', path: PATH_DASHBOARD.timesheet.approveTimesheet, icon: <GradingIcon sx={{ width: 1, height: 1 }} /> },
          { title: 'Timesheet Report', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} /> },
        ],
      }
    ];
    break;
  case "user":
    navConfig = [
      // GENERAL
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
          { title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
          { title: 'Timesheet Report', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} /> },
        ],
      }
    ];
    break;
  case "finance":
    navConfig = [
      {
        subheader: 'general',
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app_pm, icon: ICONS.dashboard },
          { title: 'WBS', path: PATH_DASHBOARD.wbs.root, icon: <Diversity3Icon sx={{ width: 1, height: 1 }} /> },
          {
            title: 'Timesheet', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar,
            children: [
              { title: 'Create', path: PATH_DASHBOARD.timesheet.newTimesheet, icon: ICONS.calendar },
            ]
          },
          {
            title: 'Reports', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} />,
            children: [
              { title: 'Timesheet', path: PATH_DASHBOARD.reports.root, icon: <SignalCellularAltIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Resource Allocation', path: PATH_DASHBOARD.resReport.root, icon: <AnalyticsIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Resource Utilization ', path: PATH_DASHBOARD.resourceShedule.root, icon: <LeaderboardIcon sx={{ width: 1, height: 1 }} /> },
              { title: 'Delivery', path: PATH_DASHBOARD.deliveryReport.root, icon: <TrendingUpIcon sx={{ width: 1, height: 1 }} /> },]
          },
        ],
      },
    ];
    break;
  default:
    break;
}

export default navConfig;

