import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Axios from '../../../utils/Axios';
import API from '../../../utils/Api';

// ----------------------------------------------------------------------

const ERRORMSG = {
  NEWUSER: 'Default password,Generate new password',
  INCORRECTPASS: 'Invalid credentials',
  NOUSER: 'No user found with this email id'

}
export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  function changePassword(e) {
    window.open(API.Resetpassword, '_blank')
  }

  function obfuscatePassword(password) {
    return btoa(password); // Base64 encode the password
  }

  const onSubmit = async (data) => {
    try {
      // debugger
      setIsLoading(true);
      const emailId = data.email;
      const password = data.password;
      const obfuscatedPassword = obfuscatePassword(password);

      Axios.POST(API.Login, { email_id: emailId, password: obfuscatedPassword })
        .then((result) => {

          if (result.authenticate) {
            localStorage.setItem(
              'authentication',
              JSON.stringify(result.authenticate)
            );
            navigate("/");
            Axios.POST(API.Start).then((result) => {
              if (result) {
                localStorage.setItem("userRole", JSON.stringify(result.usermenu));
                localStorage.setItem("user", JSON.stringify(result));
              }
            });
            setTimeout(() => {
              window.location.reload();
              setIsLoading(false);
            }, 3500);
          }
        })
        .catch((err) => {
          setError('afterSubmit', err.message);
          setIsLoading(false);
          if (err.response && err.response.data) {
            setErrorMsg(ERRORMSG[err.response.data]);

            err.response.data === 'NEWUSER' ? setIsNewUser(true) : setIsNewUser(false);
          }
        });


    } catch (error) {
      reset();
      setError('afterSubmit', error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField disabled={isLoading} name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          disabled={isLoading}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}> */}
        <Link sx={{ cursor: "pointer" }} variant="subtitle2" onClick={changePassword}>
          {isNewUser ? 'Create New Password' : 'Forgot password ?'}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
